import React from "react";
import CompPill from "../comppill/CompPill";

import "./Hero.css";
import HeroRibbon from "../HeroRibbon/HeroRibbon";
import BoxMobile from "../BoxMobile/BoxMobile";

import svg1 from "../../assets/svg1.svg";
import svg2 from "../../assets/svg2.svg";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import reactLogo from "../../assets/flexLogo_Fullxxx.png";
import dublebottle from "../../assets/dublebottle.png";
const Hero = () => {
  return (
    <section className="hero-section">
      <div className="align-items-center justify-content-between">

     <div className="container">
     <div className="row align-items-center justify-content-between" style={{marginTop: ''}}>
     <div className="col-md-8 col-lg-8">
        <div className="hero-content section-title text-center text-lg-left mt-lg-0">
        <img src={reactLogo} alt="react logo" style={{width:'150px'}} />
           <h2 className="font-weight-bold" style={{color: 'rgb(255, 255, 255)'}}> A leader in Men's Telehealth!</h2><h3 style={{color: 'rgb(255, 255, 255)', letterSpacing: '0px'}}>
           <p>ED Meds Prescribed Online &amp; Delivered Free!</p>
           </h3><p className="lead" style={{color: 'rgb(219, 216, 216)'}} />
           <div className="row" id="boxdesk01">
              <div className="col-6">
               <img src={dublebottle} style={{width:'200px'}}/>
                 {/* <div className="testimonial-quote-wrap my-lg-3 my-md-3 rounded white-bg shadow-sm p-5" style={{backgroundColor: '#000', border: ''}}>
                 <div className="client-say"><p style={{height: '21px',color:"#fff"}}>FDA APPROVED Viagra for</p></div>
                 <div className="media author-info ">
                    <div className="media-body"><h5 className="mb-0" style={{color: 'rgb(255, 255, 255)'}}>Erectile Dysfunction</h5><a className="btn solid-btn getitnow" style={{backgroundColor: '#ff9800',border: 'none', marginTop: '7%'}}>Get my Viagra <span className="ti-angle-double-right" style={{marginLeft: '0%'}} /></a><div className="image" style={{position: 'absolute', right: '12px', top: '39px'}}><a href="/land/ed/start/02/?affid=home&force=1&flow=start-v2" className="no_arrow"><img src="https://doko.md/v@1.png" alt="" style={{width: '130px'}} /></a></div>
                 </div>
              </div>
           </div> */}
        </div>
        <div className="col-6">
           <div className="testimonial-quote-wrap my-lg-3 my-md-3 rounded white-bg shadow-sm p-5" style={{backgroundColor: '#000', border: ''}}>
           <div className="client-say"><p style={{height: '21px',color:"#fff"}}>FDA APPROVED Pills for</p></div>
           <div className="media author-info">
              <div className="media-body">
                 <h5 className="mb-0" style={{color: 'rgb(255, 255, 255)'}}>Erectile Dysfunction</h5><a className="btn solid-btn getitnow" style={{backgroundColor: '#ff9800', border: 'none', marginTop: '7%'}}>Get my Pills
                 <span className="ti-angle-double-right" />
                    </a><div className="image" style={{position: 'absolute', right: '12px', top: '39px'}}><a href="/land/ed/start/02/?affid=home&force=1&flow=start-v2" className="no_arrow"><img src="https://doko.md/v@2.png" alt="" style={{width: '130px'}} /></a>
              </div>
           </div>
        </div>
     </div>
  </div>
  </div><div className="row" id="boxmobile01" style={{display: 'none'}}><div className="col-12"><div className="testimonial-quote-wrap my-lg-3 my-md-3 rounded white-bg shadow-sm p-5" style={{backgroundColor: 'rgb(28, 44, 70)', border: '1px solid rgb(47, 165, 169)', color: 'rgb(255, 255, 255)'}}><div className="client-say"><p style={{height: '21px'}}>FDA APPROVED</p></div><div className="media author-info mb-3" style={{marginTop: '1%'}}><div className="media-body"><h5 className="mb-0" style={{color: 'rgb(255, 255, 255)'}}>Erectile Dysfunction</h5><div className="row"><div className="col-6"><div><img src="https://doko.md/viagrapill.png" style={{marginTop: '6%'}} /><br /><span style={{marginTop: '0%'}}>Viagra® (sildenafil)</span></div></div><div className="col-6"><div><img src="https://doko.md/cialispill.png" style={{marginTop: '6%'}} /><br /><span style={{marginTop: '0%'}}>Cialis® (tadalafil)</span></div></div></div><div /></div></div></div></div></div>
  <div className="action-btn mt-4">
     <div className="flex-row mb-5" style={{padding: '0px'}}><img src="/flex/static/media/svg1.91e9b011ebac70e58f27598ac2d0ac19.svg" /><img src="/flex/static/media/svg2.b501edc74abf9df0fdc2caebe700bdc5.svg" />
     {/* <a className="btn solid-btn getitnow">
        START MY FREE VISIT
        <span className="ti-angle-double-right" />
     </a> */}
  </div>
  </div></div></div>
  <div className="col-md-4 col-lg-4"><center><div className="zoom-in-out-box" style={{height: '150px', width: '150px', boxShadow: 'rgba(0, 0, 0, 0.09) 0px 0px 8.9px 0px', border: '3px solid rgb(228 153 42)', backgroundColor: 'rgba(198, 131, 31, 0)', borderRadius: '50%', color: 'rgb(228 153 42)', fontWeight: 200}}><span>From</span> <br /><span style={{fontSize: '3rem', fontWeight: 700}}>$1</span> <br /><span>Per Pill</span> <br /></div></center></div>
  </div><br /></div>

      </div>

      <div className="pl-3 pr-3" data-aos="">
        <HeroRibbon />
      </div>
    </section>
  );
};

export default Hero;
