import React from "react";
import numb_list from "../../assets/numb-list.png";
import numb_doc from "../../assets/numb-doc.png";
import numb_states from "../../assets/numb-states.png";
import "./numbers.css";


const numbers = () => {
  return (
    <section
    id="reviews"
    className="testimonial-section numberssection ptb-100"
    style={{
    //   background: "url(https://doko.md/edpills/bck3threebox.png)no-repeat center center / cover",
    //   backgroundColor: "#250e0e"
    }}
  >
    <div className="container " >
      <div className="row">
        <div className="col-md-4 col-lg-4">
          <div
            className="client-info-wrap d-flex align-items-center mt-5 numb numberbox"
            style={{ backgroundColor: "#000000a8" }}
          >
            <div className="client-img">
              <img
                src={numb_doc}
                alt="client"
                width={120}
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="client-info ">
              <h5 className="mb-0" style={{ fontSize: "2rem", color: "#FFC107" }}>
                <span className="Countnum" style={{ color: "#FFC107" }}>
                  100
                </span>
                +
              </h5>
              <p className="mb-0" style={{ color: "#FFC107" }}>
                U.S Board Certified Doctors
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4">
          <div
            className="client-info-wrap d-flex align-items-center mt-5 numb numberbox"
            style={{ backgroundColor: "#000000a8" }}
          >
            <div className="client-img">
              <img
                src={numb_list}
                 alt="client"
                width={120}
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="client-info " style={{ color: "#fff" }}>
              <h5 className="mb-0 " style={{ fontSize: "2rem",  color: "#FFC107" }}>
                <span className="Countnum" style={{ color: "#FFC107" }}>
                  10,000
                </span>
                +
              </h5>
              <p className="mb-0" style={{ color: "#FFC107" }}>
                Prescriptions Delivered
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4">
          <div
            className="client-info-wrap d-flex align-items-center mt-5 numb numberbox"
            style={{ backgroundColor: "#000000a8" }}
          >
            <div className="client-img">
              <img
                src={numb_states}
                alt="client"
                width={120}
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="client-info">
              <h5 className="mb-0" style={{ fontSize: "2rem" }}>
                <span className="Countnum" style={{ color: "#FFC107" }}>
                  33
                </span>
              </h5>
              <p className="mb-0" style={{ color: "#FFC107" }}>
                States Licensed Pharmacy
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default numbers;



