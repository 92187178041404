import React from "react";
import "./boxmobile.css";

import img_viagra from "../../assets/pfizer.webp";
import img_cialis from "../../assets/cialis.webp";

const BoxMobile = () => {
  return (
    <div className="box_container">
      <div className="box_mobile-header">
       <center> <h5 style={{color:"#fff"}}>FDA APPROVED MEDS for</h5>
        <h4 style={{color:"#fff"}}>Erectile Dysfunction</h4></center>
      </div>
      <div className="box_mobile-body">
        <div className="box_flex-row">
          <div className="pills-info">
            <div className="box_flex_column">
              <img src={img_viagra} className="promo" />
              <span>Viagra® (sildenafil)</span>
            </div>

            <div className="box_flex_column">
              <img src={img_cialis} className="promo" />
              <span>Cialis® (tadalafil)</span>
            </div>
          </div>
          <div className="">

      <div className="zoom-in-out-box" style={{height: '150px', width: '150px', boxShadow: 'rgba(0, 0, 0, 0.09) 0px 0px 8.9px 0px', border: '3px solid rgb(228 153 42)', backgroundColor: 'rgba(198, 131, 31, 0)', borderRadius: '50%', color: 'rgb(228 153 42)', fontWeight: 200}}><span>From</span> <br /><span style={{fontSize: '3rem', fontWeight: 700}}>$1</span> <br /><span>Per Pill</span> <br /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxMobile;
