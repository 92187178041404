import $ from 'jquery';


import Swal from 'sweetalert2'
function sendToserver(url,data) {

    return $.ajax({
        type: 'POST',
        url: url,
        data: data,
        enctype: 'multipart/form-data',
        processData: false, // Important!
        contentType: false,
        cache: false,
        beforeSend: function (xhr) {
            if (typeof (background_mode) == "undefined") {
                //showloader_no_overlay();
            }
        }, success: function (data) {
            if (typeof (background_mode) == "undefined") {
               // hideloader_no_overlay();
            }
        }, error: function () {
            if (typeof (background_mode) == "undefined") {
              //  hideloader_no_overlay();
            }
        }
    });


}  


function statusNotify($data,$err=0) {

  if($err==1){
    Swal.fire({
      icon: 'success',
      title: '',
      text: $data,
    });

  }else if($err==2){
    Swal.fire({
      icon: 'success',
      title: $data,
      text: '',
    });

  }else{


  Swal.fire({
    icon: 'error',
    title: '',
    text: $data,
  });
}
  }  

  function isValidEmail(Email) {

    var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
    if (filter.test(Email)) {
        return true;
    } else {
        return false;
    }
   }
  function alertFields(id) {

    $("#"+id).css("border-color", "#e61d1d");
   }
   
export { sendToserver,statusNotify,isValidEmail,alertFields};